import * as Constants from "../../../config/Constants";

export default lang => ({
    GENERICSEARCH: ({ en: 'Generic Search', it: 'Ricerca Generica' })[lang],
    WHOLENAME: ({ en: 'Whole Name', it: 'Nome Completo' })[lang],
    FIRSTNAME: ({ en: 'First Name', it: 'Nome' })[lang],
    LASTNAME: ({ en: 'Last Name', it: 'Cognome' })[lang],
    BIRTHDATE: ({ en: 'Birth Date', it: 'Data di nascita' })[lang],
    BIRTHPLACE: ({ en: 'Birth Place', it: 'Luogo di nascita' })[lang],
    PASSPORTNUMBER: ({ en: 'Passport/ID Number', it: 'Numero di Passaporto/ID' })[lang],
    CITIZENCOUNTRY: ({ en: 'Citizen Country', it: 'Cittadinanza' })[lang],
    PRECISION: ({ en: 'Precision', it: 'Precisione' })[lang],
    LOWEST: ({ en: 'Lowest', it: 'Minima' })[lang],
    LOW: ({ en: 'Low', it: 'Bassa' })[lang],
    MEDIUM: ({ en: 'Medium', it: 'Media' })[lang],
    HIGH: ({ en: 'High', it: 'Alta' })[lang],
    LANGUAGE: ({ en: 'Language', it: 'Lingua' })[lang],
    SEARCH: ({ en: 'SEARCH', it: 'CERCA' })[lang],
    CLEAR: ({ en: 'Clear form', it: 'Svuota campi' })[lang],
    MASSIVE: ({ en: 'Massive Search from CSV file', it: 'Ricerca massiva da file CSV' })[lang],
    ORIGINLIST: ({ en: 'List of origin Databases', it: 'Lista Database di origine' })[lang],
    FIRST100: ({ en: 'Only the first 100 are displayed', it: 'Sono visualizzati solo i primi 100 risultati' })[lang],
    NAME: ({ en: 'Name', it: 'Nome' })[lang],
    NOTE: ({ en: 'Notes', it: 'Note' })[lang],
    VIEWPDF: ({ en: 'Print PDF', it: 'Stampa PDF' })[lang],
    NOENTITY: ({ en: 'No entity found', it: 'Nessun soggetto trovato' })[lang],
    GENERICTEXT: [
        ({ en: 'Generic search looks for the value provided in', it: 'La ricerca generica cerca in' })[lang],
        ({ en: 'every', it: 'tutti' })[lang],
        ({ en: ' field with the lowest ', it: ' i campi, con precisione ' })[lang],
        ({ en: 'precision', it: 'MINIMA' })[lang],
    ],
    HELP: [
        {
            name: ({ en: 'High', it: 'Alta' })[lang],
            text: [
                ({ en: 'has among their names and aliases, both the firstname and the lastname specified or the wholename specified', it: 'Ha tra i nomi e alias sia il nome, che il cognome, oppure il nome completo specificati' })[lang],
                ({ en: 'has as birth date the one specified or none', it: 'ha come data di nascita quella specificata o nessuna' })[lang],
                ({ en: 'has as birth place the one specified or none', it: 'ha come luogo di nascita quello specificato o nessuno' })[lang],
                ({ en: 'has as passport number the one specified or none', it: 'ha come numero di passaporto/ID quello specificato o nessuno' })[lang],
                ({ en: 'has as citizen country the one specified or none', it: 'ha come paese di cittadinanza quello specificato o nessuno' })[lang],
            ]
        },
        {
            name: ({ en: 'Medium', it: 'Media' })[lang],
            text: [({ en: 'have among their names and aliases, both the firstName and the lastName specified or the wholeName specified', it: 'ha tra i nomi e alias sia il nome, che il cognome, oppure il nome completo specificati' })[lang]]
        },
        {
            name: ({ en: 'Low', it: 'Bassa' })[lang],
            text: [({ en: 'have among their names and aliases, either the firstName or the lastName or the wholeName specified', it: 'ha tra i nomi e alias o il nome, o il cognome o il nome completo specificati' })[lang]]
        },
        {
            name: ({ en: 'Lowest', it: 'Minima' })[lang],
            text: [({
                en: 'have among their names and aliases, either the firstName or the lastName or the wholeName specified even if a portion of the string is provided. (e.g. you search Moham and get Mohammad, Mohammud, Moham Baqi etc.)',
                it: 'ha tra i nomi e alias o il nome o il cognome o il nome completo specificati, anche se viene fornita solo una porzione della stringa. (es. cerchi Moham e trovi Mohammad, Mohammud, Moham Baqi ecc.)',
            })[lang]]
        }
    ],
    NAMELIST: ({ en: 'Names', it: 'Nomi' })[lang],
    ALIASLIST: ({ en: 'Aliases', it: 'Alias' })[lang],
    TITLELIST: ({ en: 'Titles', it: 'Titoli' })[lang],
    ADDRESSLIST: ({ en: 'Addresses', it: 'Indirizzi' })[lang],
    BIRTHLIST: ({ en: 'Birth List', it: 'Date e luoghi di nascita' })[lang],
    CITIZENLIST: ({ en: 'Citizen List', it: 'Cittadinanze' })[lang],
    DOCUMENTLIST: ({ en: 'Document List', it: 'Documenti' })[lang],
    ORIGINDB: ({ en: 'Origin DB', it: 'DB di origine' })[lang],
    PUBLICATIONURL: ({ en: 'Publication URL', it: 'URL di pubblicazione' })[lang],
    ENTITYDETAILS: ({ en: 'Entity Details', it: 'Dettaglio Soggetto' })[lang],
    HELPTITLE: ({ en: 'Precision Guide', it: 'Guida Precisione' })[lang],
    SEARCHRESULTTITLE: ({ en: 'Search Completed', it: 'Ricerca Completata' })[lang],
    DBPOPUPTITLE: ({ en: 'How does BLUE work?', it: 'Come funziona BLUE?' })[lang],
    DBPOPUPTEXT: [
        ({ en: 'Our goal is to simplify your daily tasks, for this reason we keep our databases updated with ', it: 'Il nostro obiettivo è di semplificare le tue operazioni quotidiane, per questo teniamo sempre aggiornati i nostri database con i dati ' })[lang],
        ({ en: 'official ', it: 'ufficiali' })[lang],
        ({ en: 'data from the most complete antiterrorism lists.', it: ' delle più complete liste antiterrorismo.' })[lang],
    ],
    DBPOPUPTEXT2: ({ en: 'Below you will find the links to the official Websites and those to directly download the antiterrorism lists that we use to make your queries here in BLUE.', it: 'Qui sotto trovi i collegamenti ai siti Web ufficiali e quelli diretti alle liste antiterrorismo che usiamo per eseguire le tue ricerche qui su BLUE.' })[lang],
    DBPOPUPTEXT3: [
        ({ en: 'These lists comply with the law ', it: 'Liste conformi al ' })[lang],
        ({ en: ' More information on the ', it: ' Maggiori dettagli sul ' })[lang],
        ({ en: `official website of the Bank of Italy's UIF.`, it: `sito ufficiale dell'UIF della Banca d'Italia.` })[lang],
    ],
    DBPOPUPBUTTONTEXT: [
        ({ en: 'Official Website', it: 'Sito Web Ufficiale' })[lang],
        ({ en: 'Download file', it: 'Scarica il file' })[lang],
    ],
    OR: ({ en: 'Or', it: 'Oppure' })[lang],
    MASSIVEPOPUPEMAIL: ({ en: 'Insert the email where you want to receive the results', it: `Inserisci l'email in cui vuoi ricevere i risultati` })[lang],
    MASSIVEPOPUPTEXT: [
        ({ en: 'To make a massive search it is necessary to upload a ', it: 'Per effettuare la ricerca massiva è necessario caricare un file ' })[lang],
        ({ en: ' file containing, in each row, a subject to be searched ', it: ' contenente in ogni riga un soggetto da ricercare' })[lang],
        ({ en: 'Download the example file.', it: 'Scarica qui il file di esempio.' })[lang],
    ],
    MASSIVEPOPUPDROPFILE: [
        ({ en: 'Drop the CSV file here', it: 'Rilascia il file CSV qui' })[lang],
        ({ en: 'or click to select it.', it: 'o fai click per selezionarlo.' })[lang],
        ({ en: 'Confirm', it: 'Conferma' })[lang],
        ({ en: `You'll receive an email with the results when ready.`, it: 'Ti verrà inviata una email riepilogativa quando i risultati saranno pronti.' })[lang],
    ],
    MASSIVEPOPUPNOEMAIL: ({ en: `You didn't insert your email.`, it: `Non hai inserito l'email.` })[lang],
    SUBJECTTABTITLE: ({ en: 'SUBJECTS', it: 'ELENCO SOGGETTI' })[lang],
    SUBJECTDELETE: ({ en: 'Delete', it: 'Elimina' })[lang],
    MASSIVEINSTRUCTION: ({en: 'Download massive Search Instructions' , it: 'Scarica Istruzioni Ricerca massiva'})[lang],
    MASSIVESEARCHNOTAUTHORIZED:({en:'Massive search is not enabled for this user', it:'Utente non autorizzato alla ricerca massiva'})[lang],
    SUBJECTNEW: ({ en: 'NEW SUBJECT', it: 'NUOVO SOGGETTO' })[lang],
    FISCALCODE: ({ en: 'Fiscal Code', it: 'Codice  Fiscale' })[lang],
    DENOMINATION: ({ en: 'Denomination', it: 'Ragione Sociale' })[lang],
    EMAILADDRESS: ({ en: 'E-Mail Address', it: 'Indirizzo E-Mail' })[lang],
    DETAIL: ({ en: 'Detail', it: 'Dettaglio' })[lang],
    SUBJECT: ({ en: 'Subject', it: 'Soggetto' })[lang],
    TYPE: ({ en: 'Type', it: 'Tipo' })[lang],
    LEGALPERSON: ({ en: 'Legal Person', it: 'Persona Giuridica' })[lang],
    PHYSICALPERSON: ({ en: 'Physical Person', it: 'Persona Fisica' })[lang],
    ADDRESS: ({ en: 'Address', it: 'Indirizzo' })[lang],
    LOCATION: ({ en: 'Location', it: 'Comune' })[lang],
    PROVINCE: ({ en: 'Prov.', it: 'Prov.' })[lang],
    OUTSIDEITALY: ({ en: 'EE if foreign', it: 'EE per Estero' })[lang],
    POSTALCODE: ({ en: 'Postalcode', it: 'CAP' })[lang],
    FIRSTLASTNAME: ({ en: 'Last and First Name', it: 'Cognome e Nome' })[lang],
    CONFIRM: ({ en: 'Confirm', it: 'Conferma' })[lang],
    REQUIREDFIELD: ({ en: 'Required field', it: 'Campo obbligatorio' })[lang],
    CANCEL: ({ en: 'Cancel', it: 'Annulla' })[lang],
    DELETESUBJECTSPOPUPTITLE: ({ en: 'Delete Selected Subjects?', it: 'Elimina Soggetti Selezionati?' })[lang],
    EXPIREDLICENCE: (link)=> ({ en: `Your licence has expired, you may only export or delete your subjects <br> Buy or renew your license
    </br><button class="btn btn-danger mt-5" onclick="location.href='${link}'" type="button">BUY OR RENEW YOUR LICENSE</button>`, it: `Abbonamento scaduto, è consentito solo esportare o eliminare i soggetti 
    </br> Acquista o rinnova la licenza
    </br><button class="btn btn-danger mt-5" onclick="location.href='${link}'" type="button">AQUISTA O RINNOVA</button>` })[lang],
    WARNING: ({ en: 'Warning', it: 'Attenzione' })[lang],
    CONTACTSUPPORT: ({ en: 'CONTACT SUPPORT', it: 'RICHIEDI ASSISTENZA' })[lang],
    SEARCHTABTITLE: ({ en: 'SEARCH', it: 'RICERCA' })[lang],
    UPLOADCSV: ({ en: 'Upload CSV', it: 'Carica CSV' })[lang],
    FILEWRONGFORMAT: ({ en: 'Wrong file format', it: 'Estensione file errata' })[lang],
    FILEREQUIRED: ({ en: 'A file is required', it: 'Il file è obbligatorio' })[lang],
    FILEERROR:({ en:'An error occurred while processing your file', it:"Errore durante l'elaborazione del file"})[lang],
    SEARCHEMPTYFORM: ({ en: 'Warning: please insert at least one search term', it: 'Attenzione: inserire almeno un termine di ricerca' })[lang],
    SEARCHRESULT1: ({ en: 'Found ', it: 'Trovati ' })[lang],
    SEARCHRESULT2: ({ en: ' results.', it: ' risultati.' })[lang],
    CLOSE: ({ en: 'Dismiss', it: 'Chiudi' })[lang],
    SPINNERTITLE: ({ en: 'Processing your request', it: 'Stiamo elaborando la tua richiesta' })[lang],
    MAXSUBJECTSREACHED:({ en:'Your license has reached the maximum amount of subjects.', it:'Hai raggiunto il numero massimo di soggetti gestibili con questa licenza.'})[lang],
    GENERICERROR:({ en:'An error occurred while saving your data', it:'Si è verificato un errore durante il salvataggio dei dati'})[lang],
    SEARCHERROR:({ en:'An error occurred while processing your request', it:'Si è verificato un errore durante il controllo delle liste'})[lang],
    MASSIVEPOPUPTITLE:({ en:'Massive Search', it:'Ricerca Massiva'})[lang],
    MASSIVE100LIMIT:({ en:'The maximum number of subjects allowed is 100', it:'La ricerca è limitata a 100 soggetti'})[lang],
    SUCCESSTITLE:({ en:'Operation was completed successfully', it:'Operazione completata con successo'})[lang],
    SUCCESSOK:({ en:'Dismiss', it:'OK, Chiudi'})[lang],
    SEARCHDATE:({ en:'Search Date', it:'Data Ricerca'})[lang],
    SEARCHTERM:({ en:'Search Terms', it:'Termini Ricerca'})[lang],
    DOWLOADOK:({ en:'File has been successfully downloaded', it:'Il file è stato scaricato correttamente'})[lang],


})

